<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <rect width="24" height="24" fill="url(#pattern0)" />
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlink:href="#image0_123_755" transform="scale(0.0111111)" />
      </pattern>
      <image id="image0_123_755" width="90" height="90" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEx0lEQVR4nO2cTaiVRRjHf/bhvUpGCpFuQgqpfRfai2AgUoQrXUhQeUH62mSbsFxJm8SN6cJtEX1AwlE3ogtTwVurIOpapiVBbronu938GBl4LhwO73nf+XjnPTNn5gfDhfueM+/M/8x5nmeeed4DhUKhUCgUCoVCoVDonCeBs8BT3d86L5GvAAr4rYgdXmSVs9irgQPAPHBnSBDd/gF+Br4CXpHXm7IR+LWiTyX/19ebWAnsBS7IWJRBi47VMgFl0a4DLxv2f7ahL329jg3AZcvxRSn0AYdJ6HYPeCfwil7pKHInQm+UVWLylUTMhfIQ+wXcxDYxG3s9xhaUwQmZ2r8lj8nodg14ONDYLsYotOuqmfcUWrddgb5t/diE9rGDH7Yg9GeB5jU2+xvCs68CvvUU+pdchPaNVVcBH0isXBVHN7VbuQjtY6NNeAJ4G1gsQrt7dhveqIk8slnRrp7dhnUSOw9P/DsyFDo0/1ZM/IRHf7Z5jAUyYbFi8tqRuuCSxzhPJvxXMfltDv245jFmyVTou8DjDv245DEuGW73J4Kloclr2+rCRQeR15MRS0MC7HPsxySP0RebvCenlbzM/0NibMKNrEM3E24PiHIOd4rQDQzmQnaSgdAhd4AmQt8ApjDjaeB94AzwR81hw0JsOoTOaZgI/SZmZQZfSAgYYjMSVIeQWToTtGi/A9MNr3vR4YRkNhYdfPLOOud8VHLHN4GPLL76g9yTzUYdm2tSqm1sRtqoFQl2knK04vU9B7GvNLxnRmxtyM2Ib61IsE9yRc3XuGcp9vaaa88CfxmK67MZCb6iR93EpPO6VdYzsLkmju9azT30RucY8DywhkR8lYu3/bhhhfU8xNZJpR9r+taJqC0kGn3Zxo9TImbbYj/akO7U4eAOJm8/UcseA9vZsxB7WjYho/rSEcprZMicoaM6ZSj2g2J3R/XzHhkyYxlynTYUW0c0Byvef5hMqVt5qgUz8u7ACflx+QBcnOo2OYP8BvheIplb0nQM/6kks6LMT6/xKBjsWYj9quQ2HrIY2yY5PLgwopRhVLsKbCUyTJygChT6jYqAdko+22dc+oN5iwSdoAos9rRk/W60MJ7lppNcL5GgE1QtOMiq0oLXJeunArTrlg8wReMEVU07aSn2dinrtb1P39Jm7yZRJ6haFNuVx6SwsqoMbbhpJ5ysE1QRiI08/dU0np9I3AmqDqORUaw3NDXJO0EVwEHaYjKWiXCCasxiRyn0Iw7HSSpysaMUOqQTVGMSO0qhQztBNQYH2fS0mL4+kU5QdSz2fGzhXZdOUHUodtMTvvuZgJ2g8hDbpUjH9gnf83J94p2g6lDs5Sd8l+Tv/q5FHrcTVB2JPXZicIKqph1iQojFCaoRre94jhgVsTlBVdF0dekDqRbExOYE52QsVRVR+mQ85gL76J1gX8yW9hHLTIlN7stKPi75l5gL7KN1gpflHLCuMnSFoV3upAw3JSe4IAXtz7U8h+CF5amkQ3+QIpe1Hf52aVS/YTobUNxF4PNAdc3J/VDsXIKrN7mfPp5JwPa6EF0c/UlHkUP2XJ2A1ZsEpo+ZldXryddl9XaDfnjy77J6u+EZ4EvgTzm8PFJsb6FQKBQKhUKhUCiQPvcBXWWEECMj/CcAAAAASUVORK5CYII=" />
    </defs>
  </svg>
</template>

<script>
export default {
  name: "Confetti",
};
</script>
