<template>
  <button :class="getClass()">
    <slot v-if="!iconOnly" />
    <component :is="iconComponent(icon)" v-if="icon" class="icon" />
  </button>
</template>

<script>
import Arrow from "@/components/assets/icons/ArrowIcon.vue";
import Confetti from "@/components/assets/icons/ConfettiIcon.vue";
import Menu from "@/components/assets/icons/MenuIcon.vue";

export default {
  name: "ComponentButton",
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    gradient: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    condensed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {
    iconComponent(icon) {
      const components = {
        Arrow,
        Confetti,
        Menu,
      };
      return components[icon] || null;
    },
    getClass() {
      return {
        button: !this.iconOnly,
        bgGradient: this.gradient,
        dark: this.dark,
        light: this.light,
        buttonHeight: !this.condensed,
        condensed: this.condensed,
        iconOnly: this.iconOnly,
        notIconOnly: !this.iconOnly,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$light: #f5f5f5;
$dark: #303030;
.dark {
  background: $dark;
}
.light {
  color: $dark;
  background: $light;
  & > svg {
    path {
      fill: $dark;
    }
  }
}
.bgGradient {
  color: $light;
  background: linear-gradient(90deg, #d19cf5 0%, #94dde6 100%);
  & > svg {
    path {
      fill: $light;
    }
  }
}
.buttonHeight {
  height: 64px;
}
.condensed {
  height: 30px;
  max-width: 110px;
}
.iconOnly {
  height: 48px;
  width: 48px;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notIconOnly {
  padding: 14px 38px;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 500px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  transition: all 0.15s ease;
  & > svg {
    width: 24px;
    height: 24px;
  }
}
</style>
