<template>
  <div class="navigation" :class="getClass()">
    <div class="logo">
      <router-link to="/">
        <img src="@/components/assets/branding/logo.svg" alt="Logo">
      </router-link>
    </div>
    <div class="container">
      <router-link
        v-for="item in navigation"
        :key="item.label"
        :to="item.link"
        class="navigationLink"
      >
        {{ item.label }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComponentAppHeader",
  props: {
    condensed: {
      type: Boolean,
      default: false,
    },
    navigation: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    getClass() {
      return {
        navigationCondensed: this.condensed,
        navigationBig: !this.condensed,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$buttonWidth: 170px;
.navigation {
  padding: 32px 128px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(217, 217, 217, 0.01);
  backdrop-filter: blur(16px);
  transition: all 0.3s linear;
  position: fixed;
  top: 0;
  z-index: 10;
}

.button {
  width: $buttonWidth;
  height: 100%;
  margin-left: 64px;
}

.cta {
  .button {
    width: $buttonWidth;
    height: 100%;
    margin-left: 0px!important;
    opacity: 1;
  }
}

.navigationBig {
  height: 120px;
  .container {
    height: 64px;
    padding: 19px 32px;
  }
}
.navigationCondensed {
  height: 80px;
  .container {
    height: 48px;
    padding: 8px 8px 8px 32px;
  }
}

.container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 103px;
  border: 1px solid #888;
  background: rgba(217, 217, 217, 0.01);
  transition: all 0.3s linear;
  & > a:not(:first-child) {
    margin-left: 64px;
  }
  & > a:not(:last-child) {
    margin-right: 0px;
  }
}

.buttonCondensed-enter-active {
  animation: buttonCondensed-in 0.3s linear;
}
.buttonCondensed-leave-active {
  animation: buttonCondensed-in 0.4s reverse linear;
}

@keyframes buttonCondensed-in {
  0% {
    margin-left: 0px;
    width: 0px;
    opacity: 0;
    padding: 0px 0px;
  }
  50% {
    margin-left: 32px;
    width: $buttonWidth;
    opacity: 0;
    padding: 14px 40px;
  }
  100% {
    margin-left: 64px;
    width: $buttonWidth;
    opacity: 1;
    padding: 14px 40px;
  }
}
</style>
