import { createRouter, createWebHistory } from "vue-router";

import { useUserStore } from "@/stores/user";
import { getAuthState } from "@/services/auth.service";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      component: () => import("@/views/HomeView.vue"),
    },
    {
      path: "/access",
      component: () => import("@/views/Access.vue"),
    },
    {
      path: "/profile",
      component: () => import("@/views/Profile.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/projects",
      component: () => import("@/views/Projects.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/project/:projectId",
      component: () => import("@/views/Project.vue"),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/project/:projectId/create",
      component: () => import("@/views/project/ProjectStepCreate.vue"),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/project/:projectId/shoot",
      component: () => import("@/views/project/ProjectStepShoot.vue"),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/project/join/:projectPincode",
      component: () => import("@/views/project/JoinProjectView.vue"),
      props: true,
    },
  ],
});

router.beforeResolve(async (to, from) => {
  console.log(`🛣️ Route movement from ${from.path} to ${to.path}`);

  const authState = await getAuthState();

  if (to.meta.requiresAuth && !authState.uid) return "/access";
  return true;
});

export default router;
