import { createApp } from "vue";
import { createPinia } from "pinia";
import { registerPlugins } from "@/plugins";

import App from "./App.vue";

import * as Sentry from "@sentry/vue";
import { createSentryPiniaPlugin } from "@sentry/vue";

import { firebaseApp } from "@/services/firebase.service";

import packageJson from "../package.json";

import router from "@/router";

const pinia = createPinia();
pinia.use(createSentryPiniaPlugin());

const app = createApp({
  ...App,
  provide: {
    firebaseApp,
  },
});

import { createHead } from "unhead";

createHead();

import { createNotivue } from "notivue";

import "notivue/notification.css";
import "notivue/animations.css";

const notivue = createNotivue({
  position: "bottom-right",

  avoidDuplicates: true,
  pauseOnTabChange: false,

  notifications: {
    global: {
      duration: 5000,
    },
  },
});

Sentry.init({
  app,
  dsn: "https://632837c88bdbbc312daa9cb9a2a19cc7@sentry.stan1712.com/3",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      colorScheme: "system",
      autoInject: import.meta.env.VITE_SENTRY_FEEDBACK || false,

      triggerLabel: "Signaler un bug",
      formTitle: "Signaler un bug",
      submitButtonLabel: "Envoyer le rapport",
      cancelButtonLabel: "Annuler",
      confirmButtonLabel: "Confirmer",
      addScreenshotButtonLabel: "Ajouter une capture d'écran",
      removeScreenshotButtonLabel: "Supprimer la capture d'écran",
      nameLabel: "Nom",
      namePlaceholder: "Byeolbit",
      emailLabel: "Email",
      emailPlaceholder: "byeolbit@upshoot.cc",
      isRequiredLabel: "(obligatoire)",
      messageLabel: "Description",
      messagePlaceholder: "Quel est le bug ? Qu'attendiez-vous ?",
      successMessageText: "Merci pour votre rapport !",
    }),
  ],
  tracesSampleRate: import.meta.env.VITE_SENTRY_SAMPLERATE || 0.5,
  tracePropagationTargets: [
    "localhost",
    "https://beta.app.upshoot.cc",
    "https://app.upshoot.cc",
  ],

  normalizeDepth: 10,

  release: packageJson.version,
  environment: process.env.NODE_ENV,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_SAMPLERATE || 0.1,
});

registerPlugins(app);

app.use(pinia);
app.use(notivue);

app.mount("#app");
