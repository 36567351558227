<template>
  <div class="title">
    <div class="content">
      <assetsGradientSecondary
        v-if="!noGradients"
        style="bottom: 0%; left: -26%;"
      />
      <span v-if="korean" class="koreanBig gradient">{{ korean }}</span>
      <h1><slot /></h1>
      <h4 v-if="!subtitle && !noSubtitle" class="description">
        Setup, Shoot, Repeat.
      </h4>
      <CompButton v-if="button" light condensed>
        Essayer
      </CompButton>
      <span v-if="subtitle && !noSubtitle" class="subtitle">{{
        subtitle
      }}</span>
    </div>
    <assetsGradientPrimary v-if="!noGradients" style="top: 0px; left: 0%;" />
  </div>
</template>

<script>
import CompButton from "@/components/CustomButton.vue";
import assetsGradientPrimary from "@/components/assets/GradientPrimary";
import assetsGradientSecondary from "@/components/assets/GradientSecondary";

export default {
  name: "ComponentTitle",
  components: {
    CompButton,
    assetsGradientPrimary,
    assetsGradientSecondary,
  },
  props: {
    korean: {
      type: String,
      default: "블로그",
    },
    subtitle: {
      type: String,
    },
    noSubtitle: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Boolean,
      default: false,
    },
    noGradients: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  display: block;
  height: 170px;
  max-width: 600px;
  white-space: nowrap;
  margin-bottom: 64px;
  overflow: visible;
  h1 {
    margin-bottom: 16px;
  }
  .button {
    margin-top: 90px;
  }
  .content {
    position: absolute;
    top: 32px;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .description {
    width: 100%;
    min-width: 400px;
    margin: 0;
    font-weight: 500;
    text-wrap: wrap;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  & > *:first-child {
    left: -32px;
    bottom: -32px;
  }
  .subtitle {
    font-weight: 500;
    color: #fff;
    width: 100%;
    text-wrap: wrap;
    overflow-wrap: break-word;
    word-break: break-word;
  }
}
</style>
