<template>
  <div class="gradient">
    <svg width="3062" height="2085" viewBox="0 0 3062 2085">
      <g filter="url(#blur)">
        <ellipse cx="1630" cy="760" :rx="isMobile ? '730' : '430'" :ry="isMobile ? '480' : '280'" fill="#88C3CE" />
      </g>
      <defs>
        <filter
          id="blur"
          x="-396"
          y="-766"
          width="3875"
          height="3617"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feGaussianBlur
            :stdDeviation="isMobile ? '75' : '200'"
            result="effect1_foregroundBlur_6_345"
          />
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: "AssetsGradientPrimary",
  computed: {
    isMobile() {
      if (window?.innerWidth) return window.innerWidth < 1400;
      else return false;
    },
  },
};
</script>
