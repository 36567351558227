<template>
  <div class="gradient">
    <svg width="3062" height="2085" viewBox="0 0 3062 2085">
      <g filter="url(#blur)">
        <ellipse :cx="isMobile ? '2480' : '1480'" :cy="isMobile ? '1845' : '845'" :rx="isMobile ? '1420' : '420'" :ry="isMobile ? '180' : '220'" fill="#AD56F1" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "AssetsGradientSecondary",
  computed: {
    isMobile() {
      if (window?.innerWidth) return window.innerWidth < 1400;
      else return false;
    },
  },
};
</script>
