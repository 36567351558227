<template>
  <Notivue v-slot="item">
    <Notification :item="item" />
  </Notivue>

  <AppHeader v-if="!isMobile" :navigation="navigation" :condensed="condensed" />
  <AppHeaderMobile v-else :navigation="navigation" :condensed="condensed" />

  <v-slide-x-transition mode="out-in">
    <div>
      <div class="mainGradient" />
      <v-divider class="pt-16" />

      <v-container class="">
        <router-view />
      </v-container>
    </div>
  </v-slide-x-transition>

  <AppFooter />
</template>

<script setup>
import { useHead } from "unhead";

useHead({
  title: "UpShoot App",
  meta: [
    {
      name: "title",
      content: "UpShoot App",
    },
    {
      name: "description",
      content: "🎥 Setup, Shoot, Repeat.",
    },

    {
      name: "og:type",
      content: "website",
    },
    {
      name: "og:url",
      content: "https://app.upshoot.cc",
    },
    {
      name: "og:title",
      content: "UpShoot App",
    },
    {
      name: "og:description",
      content: "🎥 Setup, Shoot, Repeat.",
    },

    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:url",
      content: "https://app.upshoot.cc",
    },
    {
      name: "twitter:title",
      content: "UpShoot App",
    },
    {
      name: "twitter:description",
      content: "🎥 Setup, Shoot, Repeat.",
    },
  ],
});
</script>

<script>
import { getAuthState } from "@/services/auth.service";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { useUserStore } from "@/stores/user";

import AppHeader from "@/components/AppHeader";
import AppHeaderMobile from "@/components/AppHeaderMobile";
import AppFooter from "@/components/AppFooter";

import { Notivue, Notification } from "notivue";

export default {
  name: "App",
  components: {
    AppHeader,
    AppHeaderMobile,
    AppFooter,
  },
  data() {
    return {
      listenedOnce: false,
      navigation: [],
      condensed: false,
    };
  },
  computed: {
    isMobile() {
      if (window?.innerWidth) return window.innerWidth < 1000;
      else return false;
    },
  },
  beforeUnmount() {
    window.removeEventListener("scroll", () => {
      this.condensed = window.top.scrollY > 200;
    });
  },
  beforeCreate() {
    const auth = getAuth();
    onAuthStateChanged(auth, () => {
      getAuthState(true);
      this.rebuildMenu(auth);
    });
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.condensed = window.top.scrollY > 200;
    });
  },
  methods: {
    async rebuildMenu() {
      const userStore = useUserStore();

      const preMenu = [
        { label: "Cockpit", link: "/" },
      ];

      if (!userStore.isUserLogged) preMenu.push({ label: "Connexion", link: "/access" });
      else {
        preMenu.push({ label: "Mes projects", link: "/projects" });
        preMenu.push({ label: "Mon profil", link: "/profile" });
      }

      this.navigation = preMenu;
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 600px) {
  :root {
    --nv-root-x-align: center;
    --nv-z: 4000;
  }
}

:root {
  --nv-z: 4000;
}

.rounded-upst {
  border-radius: 8px;
}

.project-card {
  padding: 16px;

  color: white!important;
  background: #3c3c3ce6!important;

  border: 0 solid #94dde6;
  border-radius: 8px;

  transition: background .3s;
}
.project-tag {
  padding: 0px 8px 0px 8px!important;
  margin: 8px!important;
  border: 1px solid!important;

  background: linear-gradient(84.94deg, rgba(209, 156, 245, 0.3) 0%, rgba(148, 221, 230, 0.3) 100%)!important;

  border-color: #CE9EF3!important;
}
</style>
