<template>
  <div class="navigation">
    <div class="logo mt-1">
      <router-link to="/">
        <img src="@/components/assets/branding/logo.svg" alt="Logo" width="70%">
      </router-link>
    </div>
    <CompButton
      gradient
      icon-only
      icon="Menu"
      @click="open = !open"
    />
  </div>
  <div v-show="isMobile && open" class="overlay" @click="open = !open">
    <div class="container">
      <router-link
        v-for="item in navigation"
        :key="item.label"
        :to="item.link"
        class="navigationLink"
      >
        {{ item.label }}
      </router-link>
    </div>
  </div>
</template>

<script>
import CompButton from "@/components/CustomButton.vue";

export default {
  name: "ComponentNavigation",
  components: {
    CompButton,
  },
  props: {
    navigation: {
      type: Array,
      default: () => [],
    },
    condensend: {
      type: Boolean,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    isMobile() {
      if (window?.innerWidth) return window.innerWidth < 1000;
      else return false;
    },
  },
  methods: {
    getClass() {
      return {
        navigationCondensed: this.condensed,
        navigationBig: !this.condensed,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1000px) {
  .navigation {
    padding: 16px;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 99;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 32px;
    backdrop-filter: blur(32px);
    .navigationLink {
      font-size: 24px;
      color: white;
      text-decoration: none;
    }
    .cta {
      margin-top: 32px;
    }
  }
}
.navigation {
  height: 64px;
  padding: 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(217, 217, 217, 0.01);
  backdrop-filter: blur(16px);
  transition: all 0.3s linear;
  position: fixed;
  top: 0;
  z-index: 10;
  .button {
    width: 96px;
    height: 100%;
    margin-left: 64px;
  }
}
</style>
