<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path d="M23.5679 11.9928L19.3179 7.75684L17.9059 9.17384L19.7409 11.0038L1.43188 10.9998V12.9998L19.7369 13.0018L17.9159 14.8298L19.3319 16.2418L23.5679 11.9928Z" fill="white" />
  </svg>
</template>

<script>
export default {
  name: "ArrowIcon",
};
</script>
